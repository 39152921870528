<template>
  <CrmList />
</template>

<script>
  export default {
    components: {
      CrmList: () => import('@/components/comercial/crm/CrmList')
    }
  }
</script>